import singleSpaReact from "single-spa-react";
import singleSpaCanopy from "single-spa-canopy";
import React from "react";
import ReactDOMClient from "react-dom/client";

import { getSofePath } from "canopy-urls!sofe";
import "src/common/card-header.component.js";
import "src/common/table-header.component.js";
import "src/common/data.helpers.js";
import "src/common/date.helpers.js";
import Routes from "./routes.component.js";

__webpack_public_path__ = getSofePath("canopy-admin-ui"); // eslint-disable-line

const reactLifecycles = singleSpaReact({
  React,
  ReactDOMClient,
  rootComponent: Routes,
  domElementGetter,
});

const canopyLifecycles = singleSpaCanopy({
  domElementGetter,
  featureToggles: [],
  childAppName: "canopy-admin-ui",
});

export const bootstrap = [
  canopyLifecycles.bootstrap,
  reactLifecycles.bootstrap,
];

export const mount = [canopyLifecycles.mount, reactLifecycles.mount];

export const unmount = [reactLifecycles.unmount, canopyLifecycles.unmount];

export const unload = [canopyLifecycles.unload];

function domElementGetter() {
  let el = document.getElementById("canopy-admin-ui");
  if (!el) {
    el = document.createElement("div");
    el.setAttribute("id", "canopy-admin-ui");
    document.body.insertBefore(el, document.body.childNodes[0]);
  }
  return el;
}
